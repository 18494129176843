import React from "react"
import { Button } from "antd"
import { useSelector } from "react-redux"

import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import { Banner } from "../../components/BannerMaps/BannerMaps"
import { SectionTabsInformation } from "../../components/SectionTabsInformation/SectionTabsInformation"
import { SmartConnection } from "../../components/SmartConnection/SmartConnection"
import { SectionInterest } from "../../components/MayInterestYou/MayInterestYou"
import { SectionFollowMe } from "../../components/PageProduct/SectionFollowMe/SectionFollowMe"

import img_banner from "../../assets/images/generators/bannerTechnologies.png"
import SEO from "../../components/seo"
import post from '../../assets/images/poster-white.jpg';

const Product = ({
  data: { contentfulPaginaProducto: contentfulPageProduct },
}) => {
  const { languageISO639 } = useSelector(state => state.language)

  const redirect = url => {
    window.open(url, "_blank")
  }

  if (!contentfulPageProduct) return null

  const {
    seo,
    banner,
    link_tms,
    section_2_lms_items,
    section_3_app_items,
    study_cases,
    subscribe,
    ebook,
  } = contentfulPageProduct

  return (
    <Layout section="section_ligth">
      <SEO {...seo} lang={languageISO639} />
      <div className="Product">
        <Banner
          image={img_banner}
          post={post}
          multimedia={{ src: banner.image }}
          title={banner.title}
          linkTopTitle={link_tms}
          button3={
            <Button
              className="btn--change"
              onClick={() => redirect(banner.link?.link.link)}
            >
              {banner.link.label}
            </Button>
          }
        />
        <SectionTabsInformation section="LMS" items={section_2_lms_items} />
        <div className="SectionTabsInformation--two">
          <SectionTabsInformation
            section="APP"
            items={section_3_app_items}
            caroucel
          />
        </div>
        <SmartConnection
          title={contentfulPageProduct.title_liftit_connection}
          description={contentfulPageProduct.description_liftit_connection}
          servicesApi={contentfulPageProduct.serviciosApi}
        />
        <SectionInterest studiesCases={study_cases} />
        <SectionFollowMe
          title={subscribe.title}
          description={subscribe.description?.description}
          titleEbook={ebook.label}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageProduct($locale: String) {
    contentfulPaginaProducto(language: { language: { eq: $locale } }) {
      seo {
        ...SEOFragment
      }
      banner {
        ...SectionFragment
      }
      link_tms {
        ...LinkFragment
      }
      section_2_lms_items {
        ...SectionFragment
      }
      section_3_app_items {
        ...SectionFragment
      }
      services_api {
        ...ServiceApiFragment
      }
      study_cases {
        ...SectionFragment
      }
      subscribe {
        ...SectionFragment
      }
      ebook {
        ...LinkFragment
      }
    }
  }
`

export default Product
