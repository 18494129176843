import React from "react"
import { Form, Input, Button, Checkbox } from "antd"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useContentfulGeneral } from "../../../contenful/querys/General"
import point_ from "../../../assets/images/point.png"

export const SectionFollowMe = ({ title, description, titleEbook }) => {
  const { t } = useTranslation()

  const { languageISO639 } = useSelector(state => state.language)
  const contentfulGeneral = useContentfulGeneral(languageISO639)

  const onFinish = values => {
    /* eslint-disable no-console */
    console.log("Success:", values)
    /* eslint-enable no-console */
  }

  const onFinishFailed = error => {
    /* eslint-disable no-console */
    console.log("Failed:", error)
    /* eslint-enable no-console */
  }

  return (
    <div className="SectionFollowMe">
      <div className="SectionFollowMe__content">
        <div className="SectionFollowMe__content--box-top">
          <div className="SectionFollowMe__content--box-top--left">
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
          <div className="SectionFollowMe__content--box-top--right">
            <div className="card">
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <div className="follow">
                  <Form.Item
                    name="username"
                    rules={[
                      { required: true, message: t("form.error.required") },
                      { type: "email", message: t("form.error.email") },
                    ]}
                  >
                    <Input placeholder={t("general.email")} />
                  </Form.Item>
                  <Form.Item className="cont-btn">
                    <Button className="btn btn--primary" htmlType="submit">
                      {t("button.subscribe")}
                    </Button>
                  </Form.Item>
                </div>

                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  className="agreement"
                >
                  <Checkbox>
                    {t("form.check_subscribed")}{" "}
                    <a
                      href={contentfulGeneral.privacy_policies}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="politics"
                    >
                      {t("form.privacy_policies")}
                    </a>
                  </Checkbox>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <div className="ebook">
          <div className="ebook--cont">
            <img className="point" src={point_} alt="point" />
            <h1>{titleEbook}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
