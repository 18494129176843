import React, { useState, useRef } from "react"
import Slider from "react-slick"
import ReactMarkdown from 'react-markdown'

import { Multimedia } from "../Multimedia/Multimedia"

export const SectionTabsInformation = ({ section, items, caroucel, video }) => {
  const [itemSelect, setItemSelect] = useState(items[0])
  const tabsRef = useRef()
  const carouseImagesRef = useRef()

  const onChangeTab = (item, index) => {
    setItemSelect(item)
    if (carouseImagesRef.current) carouseImagesRef.current.slickGoTo(index)
  }

  const onChangeImages = index => {
    setItemSelect(items[index])
    tabsRef.current.slickGoTo(index)
  }

  return (
    <div className="SectionTabsInformation">
      <div className="SectionTabsInformation__content">
        <div className="caroucel-tabs">
          <Slider
            ref={tabsRef}
            dots={false}
            speed={1}
            slidesToShow={3}
            slidesToScroll={1}
          >
            {items?.map((item, index) => (
              <div key={`tab${index}`} className="title-tab">
                <div
                  onClick={() => onChangeTab(item, index)}
                  className={`${itemSelect.key === item.key && "active"}`}
                >
                  {item.name}
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="SectionTabsInformation__content__tabs">
          {items?.map((item, index) => (
            <div key={index} className="title-tab">
              <div
                onClick={() => onChangeTab(item, index)}
                className={`${itemSelect.key === item.key && "active"}`}
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
        <div className="content--right">
          <div className="SectionTabsInformation__content__body">
            <div className="SectionTabsInformation__content__body__texts animate__animated animate__fadeIn">
              <h3 className="category">{section}</h3>
              <h2 className="title">{itemSelect.title}</h2>
              <ReactMarkdown className="text" source={itemSelect.description?.description} />
            </div>
            <div className="SectionTabsInformation__content__body__img animate__animated animate__fadeInRight">
              {!caroucel && (
                <div className="imagen">
                  <Slider
                    ref={carouseImagesRef}
                    dots={false}
                    infinite={true}
                    speed={300}
                    slidesToShow={1}
                    slidesToScroll={1}
                    afterChange={onChangeImages}
                  >
                    {items?.map((item, index) => (
                      <div key={`carousel${index}`} className={`image-caroucel`}>
                        <>
                          {!video && (
                            <img
                              className="img--body"
                              src={itemSelect.image}
                              alt="technologie"
                            />
                          )}
                          {video && (
                            <Multimedia
                              interval={5000}
                              className={"video-sectiontabs"}
                              src={item.image}
                            />
                          )}
                        </>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          </div>
          {caroucel && (
            <div className="caroucel--block">
              <Slider
                ref={carouseImagesRef}
                dots={false}
                infinite={true}
                speed={500}
                slidesToShow={3}
                slidesToScroll={1}
                afterChange={onChangeImages}
              >
                {items?.map((item, index) => (
                  <div
                    key={`carousel${index}`}
                    className={`caroucel-img caroucel-img--${index}`}
                  >
                    <img className="img" alt="imgCaroucel" src={item.image} />
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
